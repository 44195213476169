[
    {
        "icon": "book-reader",
        "title": "Analyse case studies",
        "description": "Review the 5 case studies based on critical issues in Malaysia and analyse the problems."
    },
    {
        "icon": "brain",
        "title": "Think Tank: Brainstorm Or Brain Jam",
        "description": "Explore innovative solutions with technology. Refine your idea, learn how to make it sustainable and future-proof."
    },
    {
        "icon": "cogs",
        "title": "Prototyping Session: Ideas To Reality",
        "description": "Transform your idea into a working model. Learn how to build epic prototypes and make your idea a reality."
    },
    {
        "icon": "mountain",
        "title": "Pitching & Presentation: Take It To The Top",
        "description": "Perform your best and present your project to the world. Learn the secrets of pitching and how to make people drop their jaws."
    },
    {
        "icon": "paper-plane",
        "title": "Submit Your Solution",
        "description": "Send in your solution to be evaluated by top industry players."
    },
    {
        "icon": "microphone",
        "title": "Pitch Your Solution",
        "description": "Top 15 teams will be shortlisted to pitch your solution to the world, with the opportunity to be noticed by potential investors and collaborators."
    }    
]