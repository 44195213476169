[
    {
        "type": "platinum",
        "name": "Web Bytes Sdn Bhd",
        "logo": "https://data.vhackusm.com/sponsors/xilnex.png",
        "website": "https://www.xilnex.com/"
    },
    {
        "type": "diamond",
        "name": "Deriv Services Sdn. Bhd.",
        "logo": "https://data.vhackusm.com/sponsors/deriv.png",
        "website": "https://deriv.com/"
    },
    {
        "type": "gold",
        "name": "Micro Modular System Sdn Bhd",
        "logo": "https://data.vhackusm.com/sponsors/mms.png",
        "website": "#"
    },
    {
        "type": "silver",
        "name": "Hilti Asia IT Sdn Bhd",
        "logo": "https://data.vhackusm.com/sponsors/hilti.PNG",
        "website": "#"
    },
    {
        "type": "silver",
        "name": "Virtualtech Frontier Sdn Bhd",
        "logo": "https://data.vhackusm.com/sponsors/vtf.png",
        "website": "#"
    },
    {
        "type": "benefitinkind",
        "name": "United Overseas Bank (Malaysia) Berhad",
        "logo": "https://data.vhackusm.com/sponsors/uob.png",
        "website": "#"
    },
    {
        "type": "benefitinkind",
        "name": "Springtech Ventures Sdn Bhd",
        "logo": "https://data.vhackusm.com/sponsors/springtech.png",
        "website": "#"
    },
    {
        "type": "benefitinkind",
        "name": "GoMonsta Asia Sdn Bhd",
        "logo": "https://data.vhackusm.com/sponsors/monsta.png",
        "website": "#"
    }
]