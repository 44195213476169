[
    {
        "question": "What is Varsity Hackathon 2022?",
        "answer": "Varsity Hackathon 2022 is a platform for university students to gain new hackathon experience by exploring the right way to solve challenges using critical thinking, learning how to validate and market their idea, and developing a prototype."
    },
    {
        "question": "Is V Hack 2022 free of charge?",
        "answer": "Yes, it is completely free! "
    },
    {
        "question": "Who can participate in V Hack 2022?",
        "answer": "All undergraduate students from Malaysian universities or our program partner universities outside Malaysia are eligible to join. <br> To be a program partner, contact <a href=\"mailto:publicity@vhackusm.com\">publicity@vhackusm.com</a>"
    },
    {
        "question": "Can I join if I don’t have any prior coding knowledge?",
        "answer": "Sure! Anyone from any background can participate in V Hack 2022. We will not primarily focus on coding in our hackathon."
    },
    {
        "question": "How many people can be in a team?",
        "answer": "Each team can have a maximum of 5 members. All members in the team must attend the <b>same university</b>."
    },
    {
        "question": "Can I join solo?",
        "answer": "Yes, you can join solo if you wish. Feel free to grab the opportunity to expand your network in V Hack 2022!"
    },
    {
        "question": "How do I register?",
        "answer": "V Hack 2022 registration opens on 20<sup>th</sup> February 2022, 12.00AM on this website. Look out for the shiny sign-up button when it is open! <b><br>Registration closes on 21<sup>st</sup> March 2022, 11.59PM. <b>* (GMT +8 Malaysia Time)"
    },
    {
        "question": "Do I need to be physically in Universiti Sains Malaysia to participate in V Hack 2022?",
        "answer": "No, V Hack 2022 will be held entirely online."
    },
    {
        "question": "What will we do as participants in V Hack 2022?",
        "answer": "Refer to <a href=\"#section-event-timeline\">Your V Hack Journey</a> or view our post <a href=\"https://www.instagram.com/p/CZrOEAjp1xm/?utm_medium=copy_link\"  target=\"_blank\">here</a>.  <br><br> In summary, five case studies will be released, and you will be required to develop your project based on any case study of your choice. We will also be conducting sessions to guide you on how to analyse the problem, brainstorm and validate realistic solutions, and develop amazing prototypes to wow the world."
    },
    {
        "question": "I need to know more! ",
        "answer": "Registered & verified participants will receive further details regarding the participation guidelines and instructions. Stay tuned! <br><br>If you have further questions, feel free to get in touch with us via e-mail at <a href=\"mailto:contact@vhackusm.com\">contact@vhackusm.com</a>."
    }

]