let timelineJson = require('../data/timeline.json');
let faqJson = require('../data/faq.json');
let sponsorJson = require('../data/sponsor.json');

document.addEventListener("DOMContentLoaded", function(event) { 
    generateTimelineData();
    generateFaqData();
    generateSponsorData();

});


async function generateTimelineData() {
    let container = document.querySelector("#timeline-blocks");

    timelineJson.forEach(day => {
    
        container.innerHTML += `
            <div class="timeline-block d-flex align-items-center mx-auto" data-aos="fade-up" data-aos-duration="800">
                <button type="button" class="btn rounded-circle timeline-circle my-3 mx-4"><i class="fas fa-${day.icon}"></i></button>
                <div class="pe-4">
                    <h5>${day.title}</h5>
                    <p class="timeline-desc text-wrap fw-lighter">${day.description}</p>
                </div>
            </div>
        `
    })
}

async function generateFaqData() {

    let container = document.querySelector("#accordion-faq");

    let i = 0;
    faqJson.forEach(faq => {

        container.innerHTML += `
        <div class="accordion-item rounded-3 mb-2" data-aos="fade-up" data-aos-duration="${i * 50 + 600}" >
        <h2 class="accordion-header" id="panelsStayOpen-heading${i}">
          <button class="accordion-button rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse${i}" aria-expanded="false" aria-controls="panelsStayOpen-collapse${i}">
            ${faq.question}
          </button>
        </h2>
        <div id="panelsStayOpen-collapse${i}" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading${i}">
          <div class="accordion-body">
            <p>${faq.answer}</p>
          </div>
        </div>
      </div>
        `
        ++i;
    })
}

async function generateSponsorData() {

  let platinumContainer = document.querySelector("#sponsor-platinum-logo");
  let diamondContainer = document.querySelector("#sponsor-diamond-logo");
  let goldContainer = document.querySelector("#sponsor-gold-logo");
  let silverContainer = document.querySelector("#sponsor-silver-logo");
  let benefitinkindContainer = document.querySelector("#sponsor-benefitinkind-logo");

  sponsorJson.forEach(sponsor => {

    let container;

    if (sponsor.type === "platinum") 
      container = platinumContainer; 
    else if (sponsor.type === "diamond") 
      container = diamondContainer;
    else if (sponsor.type === "gold") 
      container = goldContainer;
    else if (sponsor.type === "silver") 
      container = silverContainer;
    else if (sponsor.type === "benefitinkind") 
      container = benefitinkindContainer;


    if (sponsor.website === "#") {
        container.innerHTML += `
        <div class="sponsor-logo m-2 p-2 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="800" >
            <img src="${sponsor.logo}" alt="Logo of ${sponsor.name}" class="img-fluid">
        </div>
      `
    } else {
        container.innerHTML += `
        <div class="sponsor-logo m-2 p-2 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="800" >
          <a href="${sponsor.website}" target="_blank"> 
            <img src="${sponsor.logo}" alt="Logo of ${sponsor.name}" class="img-fluid">
          </a>
        </div>
      `
    }

  })
}
